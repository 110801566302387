.input-content-wrapper {
    display:flex;
    flex-direction: column;
  }
  
  .default-input {
    margin: 25px 5px 15px 5px;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    height: 100px;
    width: 250px;
  }
  
  .add-button {
    margin: 5px 5px 25px 5px;
    padding: 15px;
    border-radius: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .text-area-title {
    margin: 25px 0px 0px 10px;
    color: white;
  }
  