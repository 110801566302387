.spin-button {
  padding: 15px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  width: 500px;
  justify-self: center;
  align-self: center;
}
